// experience cards
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nft{
  text-align: center;
  user-select:none;
  max-width: 300px;
  margin: 5rem auto;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: linear-gradient(0deg, rgba(40,44,52,1) 0%, rgba(17,0,32,.5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: .5s all;
  hr{
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin-top: 0;
  }
  ins{
    text-decoration: none;
  }
  .main{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    .tokenImage{
      border-radius: .5rem;
      max-width: 90%;
      margin: auto;
      // height: 230px;
      // object-fit: cover;
    }
    .description{
      margin: .5rem;
      color: #a89ec9;
    }
  }
  ::before{
    position: fixed;
    content: "";
    box-shadow: 0 0 100px 40px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: .7s all;
  }
  &:hover{
    border: 1px solid #ffffff44;
    box-shadow: 0 7px 50px 10px #000000aa;
    transform: scale(1.015);
    filter: brightness(1.1);
    ::before{
      filter: brightness(.5);
      top: -100%;
      left: 200%;
    }
  }
}
