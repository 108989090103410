// Contact Me Section
.contact-container {
  --color: rgba(30, 30, 30);
  --bgColor: rgba(245, 245, 245);

  display: grid;
  align-content: center;

  font-family: sans-serif;
}

ul.contact {
  --col-gap: 2rem;
  --barH: 1rem;
  --roleH: 2rem;
  --flapH: 2rem;

  width: min(60rem, 90%);
  margin-inline: auto;

  display: flex;
  flex-wrap: wrap;

  gap: var(--col-gap);
  padding-inline: calc(var(--col-gap) / 2);

  justify-content: center;
  align-items: flex-start;
  list-style: none;
}
ul.contact li {
  width: 10em;
  display: grid;
  grid-template:
    "role"
    "icon"
    "title"
    "descr";
  align-items: flex-start;
  gap: 1rem;
  padding-block-end: calc(var(--flapH) + 1rem);
  text-align: center;
  background-color: rgb(40,40,40,0.5);
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.6) var(--roleH),
    rgba(0, 0, 0, 0.4) calc(var(--roleH) + 0.5rem),
    rgba(0, 0, 0, 0) calc(var(--roleH) + 0.5rem + 5rem)
  );
  clip-path: polygon(
    calc(var(--col-gap) / -2 - 5px) 0,
    calc(100% + var(--col-gap) / 2 + 5px) 0,
    calc(100% + var(--col-gap) / 2 + 5px ) calc(100% - var(--flapH)),
    50% 100%,
    calc(var(--col-gap) / -2 - 5px) calc(100% - var(--flapH))
  );
}

/* bar */
ul.contact li::before {
  content: "";
  grid-area: role;
  height: var(--barH);
  width: calc(100% + var(--col-gap));
  margin-left: calc(var(--col-gap) / -2);
  margin-top: calc(var(--roleH) / 2 - var(--barH) / 2);
  background: grey;
  z-index: -1;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.1) 40%,
    rgba(0, 0, 0, 0.1) 60%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.4)
  );
}

/* role */
ul.contact li::after {
  content: "";
  grid-area: role;
  background: var(--accent-color);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.1) 40%,
    rgba(0, 0, 0, 0.1) 60%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.4)
  );
  height: var(--roleH);
}

ul.contact li:hover {
  background-color: transparent;
}

ul.contact li .icon,
ul.contact li .title,
ul.contact li .descr {
  padding-inline: 1rem;
  color: white;
  text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

ul.contact li .icon {
  font-size: 3rem;
}
ul.contact li .title {
  font-size: 1.25rem;
  font-weight: 700;
}

ul.contact li .descr {
  font-size: 0.9rem;
}

.credits {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
.credits a {
  color: var(--color);
}
