@use "./consts.scss" as consts;

// My Image style
.animated-border {
    position: relative;
    background: linear-gradient(0deg, #000, #272727);
    border-radius: 15%;
    width: fit-content;
}
  
.animated-border:before, .animated-border:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #ffffff, #0000ff, #00ff00,#ffff00, #ffffff, #ffffff, 
    #0000ff, #00ff00,#ffff00, #ffffff);
    background-size: 400%;
    border-radius: 15%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
}
  
@keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
}
  
.animated-border:after {
    filter: blur(50px);
}

// light-border
.light-border {
  position: relative;
  background: linear-gradient(0deg, #000, #272727);
  border-radius: 15%;
  width: fit-content;
}

.light-border:before, .light-border:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: #00fff2;
  background-size: 400%;
  border-radius: 15%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
}

.light-border:after {
  filter: blur(50px);
}
  
// Animated Box
@keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
}
  
.animated-box {
    $border-color: gold;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    overflow: hidden;
    padding: 2rem;
    background-color: $border-color;
    
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-color: $border-color;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient($border-color, $border-color), linear-gradient($border-color, white), linear-gradient($border-color, white), linear-gradient($border-color, white);
      animation: rotate 4s linear infinite;
    }
    
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background: rgb(40, 40, 40);
      border-radius: 5px;
    }
  
    .box-text {
      font-size: 1.7rem;
      @media screen and (max-width: consts.$small) {
        font-size: 1.3rem;
      }
    }
}


// freelance accounts list
@import url("https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap");

.pulse-list-container {
  display: grid;
  place-items: center;
}

ul.pulse-list {
  font: calc(1rem + 3vmin) "Rubik Moonrocks";
  list-style: none;
  background-color: rgb(40, 40, 40, 0.7);
  border-radius: 20px;
  border: gold 2px solid;
  padding: 2rem;
}

ul.pulse-list > li {
  color: gold;
  &::before {
    content: "🪐";
    transform: scale(0.2);
    display: inline-block;
    animation: pulse infinite alternate 0.9s;
  }
  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      &::before {
        animation-delay: -#{calc($i / 5)}s;
      }
    }
  }
}

@keyframes pulse {
  to {
    transform: scale(1);
  }
}