.block-container {
    position: relative;
    transition: 250ms;
    perspective: 500px;
}

.block-container .btn-back {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: inherit;
    height: inherit;
    transition: 250ms;
    transform-style: preserve-3d;
    transform-origin: bottom right;
    transform: rotateZ(15deg);
    will-change: transform;
    box-shadow: 16px 0 40px #e4e4e4;
    }

.block-container .btn-back-red {
    background: linear-gradient(135deg, #ff4b1f -20%, #ff9068 120%);
}

.block-container .btn-back-green {
    background: linear-gradient(135deg, #adfda2 -20%, #11d3f3 120%);
}

.block-container .btn-back-yellow {
    background: linear-gradient(135deg, #f7971e -20%, #ffd200 120%);
}

.block-container .btn-back-blue {
    background: linear-gradient(135deg, #0061ff -20%, #60efff 120%);
}

.block-container .btn-back-orange {
    background: linear-gradient(135deg, #ff0f7b -20%, #f89b29 120%);
}

.block-container .btn-back-pink {
    background: linear-gradient(135deg, #e81cff -20%, #40c9ff 120%);
}

.block-container .btn-back-black {
    background: linear-gradient(135deg, #0a1647 -20%, #e4e7e4 120%);
}

.block-container .btn-front {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: inherit;
    height: inherit;
    background-color: #ffffff66;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    transition: 250ms;
    transform-style: preserve-3d;
    transform-origin: top left;
    overflow: hidden;
}

.block-container:hover > .btn-back {
    transform: translateZ(20px) rotateZ(15deg) rotateX(-20deg) rotateY(-20deg);
}

.block-container:hover > .btn-front {
    transform: translateZ(80px) translateY(-5px) rotateX(15deg) rotateY(15deg);
}
