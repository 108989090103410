::-webkit-scrollbar {
    width: 0.5em;
    height: 0.7em;
}

@media (max-width: 992px) {
    ::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #00ff00;
}

::-webkit-scrollbar-thumb {
    background-color: #00ff00 !important;
    outline: 0;
    border-radius: 25px;
}

@media (max-width: 480px) {
    body {
        -webkit-overflow-scrolling: touch;
    }
}

body::-webkit-scrollbar-thumb {
    background-color: #00ff00;
    outline: 0;
    --navbar-z-index: 9999;
}

:root {
    scrollbar-color: #00ff00;
    /*scrollbar-color: #1bb394 #2f4050;*/
    scrollbar-width: thin;
}

* {
    scrollbar-color: #00ff00;
    scrollbar-width: thin;
}