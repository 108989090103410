@use "./consts.scss" as consts;

// My Name Style
.fancy-line-text {
    $font-size: 4.5rem;
    $stroke-width: $font-size * 0.05;
    position: relative;
    display: inline-block;
    font-size: $font-size;
    font-weight: 800;
    text-transform: uppercase;
    font-style: italic;
    color: transparent;
    -webkit-text-stroke-width: $stroke-width;
    -webkit-text-stroke-color: white;
    white-space: nowrap;
    $x: 1px;
    $y: 1px;
    $step: 1px;
    $color1: rgb(43, 43, 254);
    $color2: rgb(0, 255, 0);
        
    text-shadow: $x $y $color1,
                 $x + $step $y + $step $color2,
                 $x + $step * 2 $y + $step * 2 $color2,
                 $x + $step * 3 $y + $step * 3 $color2,
                 $x + $step * 4 $y + $step * 4 $color2,
                 $x + $step * 5 $y + $step * 5 $color2,
                 $x + $step * 6 $y + $step * 6 $color2,
                 $x + $step * 7 $y + $step * 7 $color2;
  
    @media screen and (max-width: consts.$small) {
      $font-size: 2.5rem;
      font-size: $font-size;
      $stroke-width: $font-size * 0.05;
      -webkit-text-stroke-width: $stroke-width;
      &:after {
        -webkit-text-stroke-width: $stroke-width;
      }
    }
  }
  
// My Title style
.neon-text {
    $font-size: 4rem;
    position: relative;
    $stroke-width: $font-size * 0.05;
    font-family: monospace;
    text-transform: uppercase;
    font-size: $font-size;
    $color: rgb(0, 194, 0);
    color: lighten($color, 25%);
    -webkit-text-stroke-width: $stroke-width;
    -webkit-text-stroke-color: lighten($color, 5%);
    // @include center;
    text-shadow: 0 0 20px lighten($color, 5%),
                 0 0 20px lighten($color, 5%),
                 0 0 20px lighten($color, 5%);
    &:after {
      content: attr(neontext);
      z-index: -1;
      position: absolute;
      color: transparent;
      -webkit-text-stroke-width: $stroke-width;
      -webkit-text-stroke-color: darken($color, 40%);
      top: -5%;
      left: -0.5%; 
    }
  
    @media screen and (max-width: consts.$small) {
      $font-size: 1rem;
      font-size: $font-size;
      font-family: fantasy;
      $stroke-width: $font-size * 0.05;
      -webkit-text-stroke-width: $stroke-width;
      &:after {
        -webkit-text-stroke-width: $stroke-width;
      }
    }
  
    @media screen and (max-width: consts.$medium) {
      $font-size: 1.5rem;
      font-size: $font-size;
      font-family: fantasy;
      $stroke-width: $font-size * 0.05;
      -webkit-text-stroke-width: $stroke-width;
      &:after {
        -webkit-text-stroke-width: $stroke-width;
      }
    }
}

// my journey
.my-journey-text {
    @media screen and (max-width: consts.$small) {
      font-size: 1rem;
    }
    font-size: 1.5rem;
}


// sweet title
.title-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  transform: skew(0, -10deg);
}

.sweet-title {
  order: 2;
  color: #ffffff;
  font-weight: 900;
  text-transform: uppercase;
  font-size: clamp(3rem, 10vw, 6rem);
  line-height: 0.75em;
  text-align: center;
  $shadow-color: black;
  text-shadow: 3px 1px 1px $shadow-color, 2px 2px 1px $shadow-color, 4px 2px 1px $shadow-color,
    3px 3px 1px $shadow-color, 5px 3px 1px $shadow-color, 4px 4px 1px $shadow-color,
    6px 4px 1px $shadow-color, 5px 5px 1px $shadow-color, 7px 5px 1px $shadow-color,
    6px 6px 1px $shadow-color, 8px 6px 1px $shadow-color, 7px 7px 1px $shadow-color,
    9px 7px 1px $shadow-color;

  span {
    display: block;
    position: relative;

    &:before {
      content: attr(data-text);
      position: absolute;
      text-shadow: 2px 2px 1px #000000, -1px -1px 1px #000000,
        -2px 2px 1px #000000, 1px -1px 1px #000000;
      z-index: 1;
    }

    &:nth-child(1) {
      padding-right: 2.25rem;
    }

    &:nth-child(2) {
      padding-left: 2.25rem;
    }
  }
}


// retron text
.retro-text {
  width: fit-content;
  position: relative;
  font-family: 'Exo';
  font-size: 3em;
  margin: 0;
  // transform: skew(-15deg);
  letter-spacing: 0.03em;

  @media screen and (max-width: consts.$medium) {
    font-size: 1.7em;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: -0.1em;
    right: 0.05em;
    width: 0.4em;
    height: 0.4em;
    background: 
      radial-gradient(white 3%, rgba(white, 0.3) 15%, rgba(white, 0.05) 60%, transparent 80%),
      radial-gradient(rgba(white, 0.2) 50%, transparent 60%) 50% 50% / 5% 100%,
      radial-gradient(rgba(white, 0.2) 50%, transparent 60%) 50% 50% / 70% 5%;
    background-repeat: no-repeat;
  }
  
  span:first-child {
    display: block;
    text-shadow: 0 0 0.2em #ffffff, 0 0 0.2em #ffffff,  0 0 5em #ffffff;
    -webkit-text-stroke: 0.15em rgba(black, 1);
  }
  
  span:last-child {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    // background-image: linear-gradient(#032d50 25%, #00a1ef 35%, white 50%, #20125f 50%, #8313e7 55%, #ff61af 75%);
    -webkit-text-stroke: 0.01em #94a0b9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
